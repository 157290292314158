import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading], button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading], button[mat-flat-button][loading]',
  standalone: true
})
export class LoadingDirective {
  private spinnerElement: HTMLSpanElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}
  
  @Input() public set loading(value: boolean) {
    this.toggleSpinner(value);
  }

  private toggleSpinner(loading: boolean): void {
    if (loading) {
      this.spinnerElement = this.createSpinner();
      this.renderer.appendChild(this.el.nativeElement, this.spinnerElement);
      this.el.nativeElement.setAttribute('disabled', 'true');
    } else {
      if (this.spinnerElement) {
        this.renderer.removeChild(this.el.nativeElement, this.spinnerElement);
        this.el.nativeElement.removeAttribute('disabled');
      }
    }
  }

  private createSpinner(): HTMLSpanElement {
    const spinner = this.renderer.createElement('span');
    spinner.classList.add('spinner');
    spinner.innerHTML = '&nbsp;';
    return spinner;
  }
}
