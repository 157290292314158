import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import moment from "moment";

export const timeRangeValidator: ValidatorFn = (control: AbstractControl) => {
    const parentFormGroup = control.parent as FormGroup | null;

    if (!parentFormGroup) {
        return null;
    }

    const startTime = parentFormGroup.get('startTime')?.value as string | undefined;
    const endTime = parentFormGroup.get('endTime')?.value as string | undefined;

    if (startTime && endTime) {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);

        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        if (endTotalMinutes <= startTotalMinutes) {
            return { invalidTimeRange: true };
        }
    }

    return null;
};

export function googleMapsLinkValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const googleMapsLinkPattern = /https?:\/\/(?:www\.)?(?:google\.com\/maps\/.*|maps\.app\.goo\.gl\/.*)/;
    const containsLink = /\bhttps?:\/\/\S+/gi.test(control.value);

    if (containsLink && !googleMapsLinkPattern.test(control.value)) {
        return { 'invalidGoogleMapsLink': true };
    }

    return null;
}

export const checkTimeValidity: ValidatorFn = (control: AbstractControl) => {
    const parentFormGroup = control.parent as FormGroup | null;

    if (!parentFormGroup) {
        return null;
    }
    
    const selectedTime = moment(control.value, 'HH:mm');
    const currentTime = moment();
    const startDate = moment(parentFormGroup.get('startDate')?.value);

    if (selectedTime.isSame(startDate, 'day') && currentTime.isAfter(selectedTime)) {
        return { invalidTimeValue: true };
    }

    return null;
}