import { ChangeDetectorRef, Component, DestroyRef, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MutationResult } from 'apollo-angular';
import {
  CreateEventMutation,
  Event,
  FieldType,
  Group,
  Hobby,
  Message,
  UpdateEventMutation
} from 'src/app/services/hobbyts.service';
import { MAT_DATE_FORMATS, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AsyncPipe, CommonModule, Location } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { getMatDialogConfig, getSnackBarConfig } from 'src/app/utils/material.utils';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileService } from '../../profile/profile.service';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal';
import { AddFieldModalComponent } from 'src/app/modals/add-field-modal';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { EventsService } from '../events.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { CustomSnackBar } from 'src/app/shared/custom-snackbar/custom-snackbar.component';
import { calculateChatName, goBack } from 'src/app/utils/profile.utils';
import { checkTimeValidity, googleMapsLinkValidator, timeRangeValidator } from 'src/app/utils/validators.utils';
import { LoadingDirective } from 'src/app/shared/loading-spinner.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StorageService } from 'src/app/services/storage.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM DD YYYY'
  }
};

@Component({
  selector: 'hb-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    }
  ],
  standalone: true,
  imports: [
    MatIconModule,
    MatSlideToggleModule,
    FormsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatAutocompleteModule,
    AsyncPipe,
    FeatureFlagDirective,
    CommonModule,
    LoadingDirective,
    MatRippleModule
  ]
})
export class CreateEventComponent implements OnInit {
  @ViewChild('timepicker') public timepicker: ElementRef;
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public createEventForm!: FormGroup;
  public isIndoor = true;
  public todayDate!: any;
  public endDate!: Date;
  public hobbyGroups: Group[] = [];
  public fields: FieldType[] = [];
  public eventId!: string | null;
  public displayEndDate = false;
  public autocompleteFilteredFields?: Observable<FieldType[]>;
  public showGetTemplate = true;
  public hobbies: Hobby[] = [];
  public selectedHobbies: Hobby[] = [];
  public isTemplateFormChanged = false;
  public isPostToTG = true;
  public isMVP = false;
  public isBlocked = false;
  public isFull: boolean;
  public loading = false;
  public showPostToTG: boolean = false;
  private templateData: Event;
  private messageId: Message[] = [];
  private maxPostsPerDay = 3;
  private badWords: string[] = [];
  private eventDetail: Event;
  private selectedHobby: Hobby[];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private activeRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private profileService: ProfileService,
    private lazyDialog: LazyDialogService,
    public dialog: MatDialog,
    private eventsService: EventsService,
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
    private location: Location,
    private destroyRef: DestroyRef,
    private storageService: StorageService
  ) { }

  public get formControls(): any {
    return this.createEventForm?.controls;
  }

  public get isPrivateEvent(): boolean {
    return this.createEventForm.get('isPrivate')?.value;
  }

  public get isIndoorEvent(): boolean {
    return this.createEventForm.get('isIndoor')?.value;
  }

  public ngOnInit(): void {
    const storedValue = this.storageService.getValue('store-event-template');
    if (storedValue) {
      this.showGetTemplate = JSON.parse(storedValue);
    }
    this.initForm();
    this.profileService
      .getHobbies()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        this.hobbyGroups = data.getAllHobbies.hobbyGroups;
        this.hobbies = data.getAllHobbies.hobbies;
        this.selectedHobbies = this.profileService.getSelectedHobbyItems();
        if(!this.featureFlagService.getFeature()) this.setSelectedHobby();
      });
    this.http.get('assets/bad-words/bad-words-list.csv', { responseType: 'text' }).subscribe((data: any) => {
      const list = data.split(`,\r\n`);
      list.forEach((word: string) => {
        if (word !== '') this.badWords.push(word);
      });
    });
    this.isMVP = this.featureFlagService.getFeature();
    this.eventsService.checkIfCityChannelExists(this.storageService.getValue('store-city') || "").pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.showPostToTG = data?.checkIfCityChannelExists || false;
    })
  }

  public displayFn(field: FieldType): string {
    return field ? `${field.title}` : '';
  }

  public async navigateToEventsPage(): Promise<void> {
    if ((!this.isBlocked && this.eventId) || this.createEventForm.touched) {
      const confirmationMsg = $localize`Your input data will be cleared, would you like to proceed?`;
      const confirmButtonText = $localize`Yes, Close`;
      const cancelButtonText = $localize`No, Stay`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
        confirmationMsg,
        confirmButtonText,
        cancelButtonText
      });
      await this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config).then((ref) => {
        ref.afterClosed().subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.location.back();
          }
        });
      });
    } else this.location.back();
  }

  public toggle(event: MatSlideToggleChange): void {
    this.displayEndDate = event.checked;
    if (this.displayEndDate) {
      this.createEventForm.controls.endDate.setValidators([Validators.required]);
    } else {
      this.createEventForm.controls.endDate.setValidators([]);
    }
  }

  public getTemplateIfExists(): void {
    this.eventsService.getEventTemplateCount().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
      if (data.data.getEventTemplateCount > 0) {
        this.getEventTemplate();
      } else {
        const confirmationMsg = $localize`You don't have any templates. Please fill the form and save your default template first`;
        const confirmButtonText = $localize`Got it`;
        const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
          confirmationMsg,
          confirmButtonText,
          hideCancelButton: true
        });
        this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config).then((ref) => {
          ref.afterClosed().subscribe(() => {
            this.storageService.setValue('store-event-template', 'false');
            this.showGetTemplate = !!this.storageService.getValue('store-event-template');
          });
        });
      }
    });
  }

  public async confirmSaveTemplate(): Promise<void> {
    let confirmationMsg = '';
    if (this.storageService.getValue('store-event-template')) {
      confirmationMsg = $localize`You have option to save only one template. Would you like to override your default template?`;
    } else {
      confirmationMsg = $localize`Would you like to save your default template?`;
    }
    const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, { confirmationMsg });
    await this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config).then((ref) => {
      ref.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.saveTemplate();
          this.storageService.setValue('store-event-template', 'true');
          this.showGetTemplate = !!this.storageService.getValue('store-event-template');
        }
      });
    });
  }

  public openSnackBar(message: string, buttonTxt: string, seconds: number = 2000): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig(seconds));
  }

  public dateChange(event: any): void {
    const startDate = new Date(event.value);
    this.endDate = new Date(startDate.setDate(new Date(event.value).getDate() + 1));
  }

  public async addField(): Promise<void> {
    const fieldInputValue = this.createEventForm.get('fieldData')?.value;
    if (fieldInputValue && !this.fields.includes(fieldInputValue)) {
      const confirmationMsg = $localize`Do you want to fill more inforamtion about this field?`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, { confirmationMsg });
      await this.lazyDialog
        .openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config)
        .then((ref) => {
          ref.afterClosed().subscribe((isConfirmed) => {
            if (isConfirmed) {
              this.openAddFieldModal();
            } else {
              this.createField({ title: fieldInputValue, city: this.createEventForm.get('city')?.value });
            }
          });
        });
    }
  }

  public postPublish(): void {
    if (this.createEventForm.valid) {
      this.loading = true;
      const postsData = this.getPostsDataFromLocalStorage();
      const eventData = this.prepareEventData();

      if (this.eventId) {
        this.editPost(eventData, postsData);
      } else {
        this.createPost(eventData, postsData);
      }
    }
  }

  public async deleteEvent(): Promise<void> {
    if (this.eventId) {
      const confirmationMsg = $localize`Do you want to delete the event?`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, { confirmationMsg });
      await this.lazyDialog
        .openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config)
        .then((ref) => {
          ref.afterClosed().subscribe({
            next: (isConfirmed) => {
              if (isConfirmed) {
                const { messageId, telegramChannelLink } = calculateChatName(this.messageId[0]);

                const snackBarText = messageId
                  ? $localize`:@@eventDeletedWithMessage:Your event was deleted, you can check it in telegram channel
                <a href="${telegramChannelLink}" target="_blank" class="cyan-event-link">${telegramChannelLink}</a>`
                  : $localize`:@@eventDeleted:Your event was deleted`;

                const snackBarConfig = getSnackBarConfig();
                snackBarConfig.data = { snackBarText };

                this.eventsService.removeEvent(this.eventId!).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
                  goBack(this.location, this.router);
                  this.snackBar.openFromComponent(CustomSnackBar, snackBarConfig);
                });
              }
            },
            error: () => {
              this.openSnackBar($localize`The event could not be deleted. Try again later or contact admin`, $localize`:@@close:Close`);
            }
          });
        });
    }
  }

  public async onTextPasted(event: ClipboardEvent): Promise<void> {
    const pastedText = event.clipboardData?.getData('text') || '';
    const startMarker = '💬°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°';
    const endMarker = '°°°°°°°°°°°°°°°°°°°°°°°°°°°°°°💬';

    let extractedText = '';

    const hasStartMarker = pastedText.includes(startMarker);
    const hasEndMarker = pastedText.includes(endMarker);

    if (hasStartMarker && !hasEndMarker) {
      extractedText = pastedText.split(startMarker)[1].trim();
    } else if (hasEndMarker && !hasStartMarker) {
      extractedText = pastedText.split(endMarker)[0].trim();
    } else if (hasStartMarker && hasEndMarker) {
      const startIndex = pastedText.indexOf(startMarker) + startMarker.length;
      const endIndex = pastedText.indexOf(endMarker);
      extractedText = pastedText.substring(startIndex, endIndex).trim();
    }

    if (extractedText) {
      this.createEventForm.get('description')?.setValue(extractedText);
      event.preventDefault();
      const confirmationMsg = $localize`We added copied info in description. The rest of info please select within correspondence fields`;
      const confirmButtonText = $localize`Got it`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
        confirmationMsg,
        confirmButtonText,
        hideCancelButton: true
      });

      await this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config);
    }
  }

  public toggleEventStatus(isFull: boolean): void {
    if (!this.isBlocked) {
      const postsData = this.getPostsDataFromLocalStorage();
      const eventData = this.prepareEventData();
      this.isFull = isFull;
      this.editPost(eventData, postsData);
    } else {
      this.eventsService.toggleStatusEvent(this.eventDetail, isFull).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
        next: () => {
          this.isFull = !this.isFull;
          const { messageId, telegramMessageLink, telegramChannelLink } = calculateChatName(
            this.eventDetail.messageId ? this.eventDetail.messageId[0] : null
          );

          const snackBarText = messageId
            ? $localize`:@@changesSavedWithMessage:Your changes have been saved, you can check it in telegram channel
              <a href="${telegramChannelLink}" target="_blank" class="cyan-event-link">${telegramChannelLink}</a> or
              <a href="${telegramMessageLink}" target="_blank" class="cyan-event-link">${telegramMessageLink}</a>.`
            : $localize`:@@changesSaved:Your changes have been saved`;

          const snackBarConfig = getSnackBarConfig();
          snackBarConfig.data = { snackBarText };
          goBack(this.location, this.router);
          this.snackBar.openFromComponent(CustomSnackBar, snackBarConfig);
        },
        error: () => {
          this.openSnackBar($localize`Error in toggle status!`, $localize`:@@close:Close`);
        }
      });
    }
  }

  private editPost(
    objToSave: Event,
    postsData: { count: number; lastPostTimestamp: number; lastEditTimestamp: number }
  ): void {
    if (moment().diff(moment(postsData.lastEditTimestamp), 'minutes') < 2) {
      this.openSnackBar($localize`The event can be modified in just 2 minutes`, $localize`:@@close:Close`);
      this.loading = false;
    } else {
      objToSave.messageId = [...this.messageId];
      objToSave.isFull = this.isFull;
      this.eventsService.editEvent(objToSave, this.eventId).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
        next: (res: MutationResult<UpdateEventMutation>) => {
          postsData.lastEditTimestamp = new Date().getTime();
          this.storageService.setValue('store-postsData', JSON.stringify(postsData));

          const { messageId, telegramMessageLink, telegramChannelLink } = calculateChatName(res.data?.updateEvent.messageId?.[0] ?? null);

          const snackBarText = $localize`
          Your changes have been saved. ${messageId
              ? $localize`You can check it in telegram channel
          <a href="${telegramChannelLink}" target="_blank" class="cyan-event-link">${telegramChannelLink}</a> or
          <a href="${telegramMessageLink}" target="_blank" class="cyan-event-link">${telegramMessageLink}</a>`
              : ''
            }`;

          const snackBarConfig = getSnackBarConfig();
          snackBarConfig.data = { snackBarText };
          goBack(this.location, this.router);
          this.snackBar.openFromComponent(CustomSnackBar, snackBarConfig);
        },
        error: () => {
          this.openSnackBar($localize`Error in update event!`, $localize`:@@close:Close`);
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
  }

  private createPost(
    objToSave: Event,
    postsData: { count: number; lastPostTimestamp: number; lastEditTimestamp: number }
  ): void {
    if (!this.canMakePost(postsData) && this.isBlocked) {
      const confirmationMsg = $localize`Please, If you need to make posts more often - contact https://t.me/hobbyts911 in Telegram.`;
      const confirmButtonText = $localize`Got it`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
        confirmationMsg,
        confirmButtonText,
        hideCancelButton: true
      });
      this.loading = false;
      this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config).then((ref) => {
        ref.afterClosed().subscribe(() => {
          return;
        });
      });
    } else {
      this.isBlocked = true;
      this.eventsService.createEvent(objToSave, this.isPostToTG).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
        next: async (res: MutationResult<CreateEventMutation>) => {
          postsData.count++;
          postsData.lastPostTimestamp = new Date().getTime();
          this.storageService.setValue('store-postsData', JSON.stringify(postsData));

          const { messageId, telegramMessageLink, telegramChannelLink } = calculateChatName(res.data?.createEvent.messageId?.[0] ?? null);

          const confirmationMsg = messageId
            ? $localize`:@@eventCreatedWithMessage:Event is created, you can check it in telegram channel
            <a href="${telegramChannelLink}" target="_blank" class="cyan-event-link">${telegramChannelLink}</a> or
            <a href="${telegramMessageLink}" target="_blank" class="cyan-event-link">${telegramMessageLink}</a>.`
            : $localize`:@@eventCreated:Event is created.`;

          const confirmButtonText = $localize`Got it`;

          const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
            confirmationMsg,
            confirmButtonText,
            hideCancelButton: true,
            createEventMessage: res.data?.createEvent.messageId?.[0] ?? null,
            shareEventId: !this.isMVP ? res.data?.createEvent.id : ''
          });

          await this.lazyDialog
            .openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config)
            .then((ref) => {
              ref.afterClosed().subscribe(() => {
                void this.router.navigate(['home']);
              });
            });
        },
        error: () => {
          this.openSnackBar($localize`The event cannot be posted. Try again later or contact admin`, $localize`:@@close:Close`);
        },
        complete: () => {
          this.isBlocked = false;
          this.loading = false;
        }
      });
    }
  }

  private canMakePost(postsData: { count: number; lastPostTimestamp: number }): boolean {
    const today = moment();
    const dateToCheck = moment(postsData.lastPostTimestamp);

    if (dateToCheck.isSame(today, 'day')) {
      if (postsData.count <= this.maxPostsPerDay && today.diff(dateToCheck, 'minutes') > 60) {
        return true;
      }
    } else {
      postsData.count = 0;
      return true;
    }

    return false;
  }

  private checkFormChanges(): void {
    const objToCompare = this.createEventForm.getRawValue();
    if (objToCompare.hobby) {
      objToCompare.hobby = objToCompare.hobby.id;
    }
    this.isTemplateFormChanged = JSON.stringify(this.templateData) !== JSON.stringify(objToCompare);

    if (this.eventId && this.eventDetail) {
      objToCompare.startDate = moment(this.createEventForm.get('startDate')?.value)
        .valueOf()
        .toString();

      const allComparisonsPassed = Object.entries(this.eventDetail).every(([key, value]) => {
        return !objToCompare.hasOwnProperty(key) || objToCompare[key] === value;
      });

      this.isBlocked = !!allComparisonsPassed;
    }
  }

  private createField(fieldInputValue: FieldType): void {
    this.eventsService
      .createField(fieldInputValue, this.createEventForm.get('city')?.value).pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ data }: any) => {
        const selectedField = this.fields.find((field) => field.title === this.createEventForm.get('fieldData')?.value);
        this.createEventForm.get('fieldData')?.setValue(selectedField);
      });
  }

  private getEventTemplate(): void {
    this.eventsService.getEventTemplate().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((data: any) => {
      if (data?.data?.getEventTemplate) {
        this.templateData = JSON.parse(data.data.getEventTemplate.templateData);
        this.parseTemplateData();
      }
    });
  }

  private async openAddFieldModal(): Promise<void> {
    const config = {
      height: '100%',
      width: '80%',
      data: { title: this.createEventForm.get('fieldData')?.value }
    };
    await this.lazyDialog.openLazyLoadedDialog<AddFieldModalComponent>(DialogNames.ADD_FIELD, config).then((ref) => {
      ref.afterClosed().subscribe((data) => {
        this.createField(data);
      });
    });
  }

  private generateForm(eventDetail: Event | null): void {
    const city = eventDetail ? eventDetail.city : this.storageService.getValue('store-cityName');
    const today = new Date();
    this.createEventForm = this.formBuilder.group({
      hobby: ['', Validators.required],
      city: [{ value: city, disabled: true }, Validators.required],
      fieldData: [eventDetail?.fieldData],
      description: [
        eventDetail?.description,
        [Validators.required, googleMapsLinkValidator, Validators.maxLength(255)]
      ],
      startDate: [eventDetail?.startDate || today, Validators.required],
      endDate: [eventDetail?.endDate],
      startTime: [eventDetail?.startTime, [Validators.required, timeRangeValidator, checkTimeValidity]],
      endTime: [eventDetail?.endTime, [timeRangeValidator, checkTimeValidity]],
      teamSize: [eventDetail?.teamSize, Validators.pattern('[0-9]*')],
      playerLimit: [eventDetail?.playerLimit, Validators.pattern('[0-9]*')],
      isIndoor: [eventDetail?.isIndoor || false],
      isPrivate: [eventDetail?.isPrivate || false]
    });

    this.triggerTimeValidation(this.createEventForm);
  }

  private triggerTimeValidation(formGroup: FormGroup): void {
    const startDateControl = formGroup.get('startDate');
    const startTimeControl = formGroup.get('startTime');
    const endTimeControl = formGroup.get('endTime');

    if (startDateControl && startTimeControl && endTimeControl) {
      startDateControl.valueChanges.subscribe(() => {
        startTimeControl.updateValueAndValidity();
        endTimeControl.updateValueAndValidity();
      });
    }
  }

  private initFieldInput(fieldData?: FieldType | null): void {
    this.eventsService.getAllFields(this.storageService.getValue('store-city') || '').pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res) => {
      if (res) {
        this.fields = res.getAllFields.map((field: FieldType) => ({
          id: field.id,
          title: field.title
        }));

        this.autocompleteFilteredFields = this.createEventForm.get('fieldData')?.valueChanges.pipe(
          startWith(''),
          map((value) => {
            if (typeof value === 'string') {
              return value;
            } else if (value && value.title) { 
              return value.title;
            } else {
              return ''; 
            }
          }),
          map((field) => this.filterFieldsByTitle(field))
        );

        if (fieldData) {
          const selectedField = this.fields.find((field) => field.id === fieldData.id);
          this.createEventForm.get('fieldData')?.setValue(selectedField);
        }
      }
    });
  }

  private filterFieldsByTitle(searchTerm: string): FieldType[] {
    const searchTermLower = searchTerm.toLowerCase();
    return this.fields.filter((field: FieldType) => field.title.toLowerCase().includes(searchTermLower));
  }

  private parseTemplateData(): void {
    this.createEventForm.patchValue(this.templateData);
    if(this.templateData.fieldData) {
      this.createEventForm
      .get('fieldData')
      ?.setValue(this.fields.filter((field) => field.id === this.templateData.fieldData!.id)[0]);
    }
    
    this.createEventForm
      .get('hobby')
      ?.setValue(this.hobbies.filter((hobby) => hobby.id === this.templateData.hobby)[0]);
    if (this.templateData.endDate) {
      this.displayEndDate = true;
    }
  }

  private saveTemplate(): void {
    const objToSave = this.createEventForm.getRawValue();
    const hobbyTypeId = objToSave.hobby.id;
    objToSave.hobby = objToSave.hobby.id;

    const createTemplate = {
      templateData: JSON.stringify(objToSave),
      hobbyTypeId
    };
    this.eventsService.createTemplate(createTemplate).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private setSelectedHobby(filterSportType?: string | null): void {
    if (this.isMVP) {
      this.selectedHobby = this.hobbies.filter((hobby) => hobby.title === 'football');
      this.createEventForm.get('hobby')?.disable();
      if(this.selectedHobby.length) {
        this.selectedHobby[0].title = this.storageService.getValue("store-language") === "uk" ? "футбол" : "football";
      }
    } else {
      const sportTypeId = filterSportType || this.selectedHobbies[0].id;
      this.selectedHobby = this.hobbies.filter((hobby) => hobby.id === sportTypeId);
    }
    this.createEventForm.get('hobby')?.setValue(this.selectedHobby[0]);
  }

  private initForm(): void {
    this.generateForm(null);
    this.todayDate = new Date();
    this.eventId = this.activeRoute.snapshot.paramMap.get('id');

    if (this.eventId) {
      this.eventsService.eventDetailsAuthorized(this.eventId).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((data: any) => {
        this.eventDetail = data.getEventDetailAuthorized as Event;
        this.generateForm(this.eventDetail as Event);

        this.createEventForm.get('startDate')?.setValue(moment(+this.eventDetail.startDate));

        this.messageId = this.eventDetail.messageId ? this.eventDetail.messageId : [];
        this.isFull = !!this.eventDetail.isFull;

        this.setSelectedHobby(this.eventDetail.hobby);
        this.initFieldInput(this.eventDetail.fieldData);
        if (this.eventDetail.endDate) {
          this.displayEndDate = true;
          this.createEventForm.get('endDate')?.setValue(moment(+this.eventDetail.endDate));
        }
        this.changeDetector.detectChanges();
        this.checkFormChanges();
        this.createEventForm.valueChanges.subscribe(() => {
          this.checkFormChanges();
        });
      });
    } else {
      this.initFieldInput();
    }

    this.createEventForm.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    
    this.createEventForm.get('description')!.valueChanges.subscribe((description) => {
      this.isBlocked = this.badWords.some((badWord) => {
        const lowerDescription = description.toLowerCase();
        const lowerBadWord = badWord.toLowerCase();

        return [' ', ''].some((space) => lowerDescription.includes(`${space}${lowerBadWord}${space}`));
      });

      if (this.isBlocked) {
        this.snackBar.open(
          $localize`Let's keep our space respectful - avoid using offensive language, please`,
          $localize`:@@close:Close`,
          getSnackBarConfig()
        );
      }
    });
  }

  // Create/Edit Post logic
  private getPostsDataFromLocalStorage(): { count: number; lastPostTimestamp: number; lastEditTimestamp: number } {
    const storedData = this.storageService.getValue('store-postsData');
    return storedData ? JSON.parse(storedData) : { count: 0, lastPostTimestamp: 0, lastEditTimestamp: 0 };
  }

  private prepareEventData(): Event {
    const objToSave = this.createEventForm.getRawValue();
    objToSave.startDate = moment(this.createEventForm.get('startDate')?.value)
      .startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    objToSave.endDate = this.displayEndDate
      ? moment(this.createEventForm.get('endDate')?.value)
        .startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      : null;
    objToSave.hobby = objToSave.hobby.id;
    objToSave.city = this.isMVP ? "Lviv" : this.storageService.getValue("store-placeId");
    return objToSave;
  }
}
