<header class="top-bar flex-row justify-center" aria-label="header" i18n-aria-label>
  <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()" matRipple [matRippleUnbounded]="true" [matRippleRadius]="50">arrow_back</mat-icon>
  <h2 class="page-label justify-center" i18n>
    {{ eventId ? 'Edit Event' : 'Add Event' }}
  </h2>
</header>
<form [formGroup]="createEventForm">
  <div class="actions mb-10 mt-16 flex-row space-between align-center" *hbFeatureFlag="true">
    <mat-slide-toggle class="private-toggle" color="primary" labelPosition="before" formControlName="isPrivate">
      @if (isPrivateEvent) {
      <span i18n>Private</span>
      } @else {
      <span i18n>Public</span>
      }
    </mat-slide-toggle>
    <button class="select-template" mat-button i18n aria-label="Click here to select template" i18n-aria-label
      (click)="getTemplateIfExists()" [disabled]="!showGetTemplate">
      Select Template
    </button>
  </div>
  <div class="add-event-form">
    <div class="select-section flex-col space-between gap-25">
      <mat-form-field appearance="fill" class="custom-mat-form-field" name="city">
        <mat-label class="mat-label" i18n>Select City</mat-label>
        <input matInput panelClass="select-panel" formControlName="city" i18n-matTooltip
          matTooltip="You can change the city in the profile settings" [matTooltipPosition]="'below'"
          #tooltip="matTooltip" [matTooltipDisabled]="isMVP"/>
      </mat-form-field>

      <mat-form-field appearance="fill" class="custom-mat-form-field">
        <mat-label class="mat-label" i18n>Select Sport Type</mat-label>
        <mat-select panelClass="select-panel" formControlName="hobby">
          <mat-optgroup [label]="'Selected'">
            @for (hobby of selectedHobbies; track hobby.id) {
            <mat-option [value]="hobby">
              {{ hobby.title | titlecase }}
            </mat-option>
            }
          </mat-optgroup>
          @for (group of hobbyGroups; track group.id) {
          <mat-optgroup [label]="group.title">
            @for (hobby of hobbies; track hobby.id) {
            @if (hobby.groupId === group.id) {
            <mat-option [value]="hobby">
              {{ hobby.title | titlecase}}
            </mat-option>
            }
            }
          </mat-optgroup>
          }
        </mat-select>
        @if (formControls?.hobby?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
      </mat-form-field>

      <mat-form-field *hbFeatureFlag="true" appearance="fill" class="custom-mat-form-field">
        <mat-label class="mat-label" i18n>Select Field Type</mat-label>
        <input type="text" matInput formControlName="fieldData" [matAutocomplete]="auto" aria-label="search"
          i18n-aria-label (blur)="addField()" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          @for (option of autocompleteFilteredFields | async; track option.id) {
          <mat-option [value]="option">
            {{ option?.title }}
          </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill" class="description-mat-form-field" *hbFeatureFlag="true">
        <mat-label class="description-text" i18n>Description</mat-label>
        <textarea matInput class="desciption-area" rows="4" maxlength="555" formControlName="description"></textarea>
        @if (formControls?.description?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="toggle-switch flex-row" *hbFeatureFlag="true">
      <mat-slide-toggle color="primary" labelPosition="before" [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="displayEndDate" (change)="toggle($event)">
        @if (displayEndDate) {
        <span i18n>Multiple Days</span>
        } @else {
        <span i18n>Single Day</span>
        }
      </mat-slide-toggle>
    </div>

    <div class="date-section flex-col space-between align-center gap-25">
      <mat-form-field appearance="fill" class="custom-mat-form-field">
        <mat-label class="mat-label" i18n>Choose a start date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startDate" [min]="todayDate"
          (click)="picker.open()"/>
        <mat-datepicker-toggle class="cyan-event-icon" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        @if (formControls?.startDate?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="fill" class="custom-mat-form-field time">
        <mat-label class="mat-label" i18n>Choose start time</mat-label>
        <input type="text" matInput [format]="24" [ngxMatTimepicker]="timepicker" class="mat-select-value-text"
          formControlName="startTime" />
        <mat-icon class="cyan-event-icon time-icon" (click)="timepicker.open()">access_time</mat-icon>
        <ngx-mat-timepicker #timepicker [minutesGap]="5"></ngx-mat-timepicker>
        @if (formControls?.startTime?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
        @if(formControls?.startTime?.errors?.invalidTimeRange) {
          <mat-error class="error-text">
            <span i18n><sup>*</sup>End time must be greater than start time</span>
          </mat-error>
        }
        @if(formControls?.startTime?.errors?.invalidTimeValue) {
          <mat-error class="error-text">
            <span i18n><sup>*</sup>Start time must be greater than current time</span>
          </mat-error>
        }
      </mat-form-field>

      @if (displayEndDate) {
      <mat-form-field appearance="fill" class="custom-mat-form-field">
        <mat-label class="mat-label" i18n>Choose a end date</mat-label>
        <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" [min]="endDate" (click)="endDatePicker.open()"/>
        <mat-datepicker-toggle class="cyan-event-icon" matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker disabled="false"></mat-datepicker>
        @if (formControls?.endDate?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
      </mat-form-field>
      }

      <mat-form-field appearance="fill" class="custom-mat-form-field time">
        <mat-label class="mat-label" i18n>Choose end time</mat-label>
        <input type="text" matInput [format]="24" [ngxMatTimepicker]="endTimePicker" class="mat-select-value-text"
          formControlName="endTime" />
        <mat-icon class="cyan-event-icon time-icon" (click)="endTimePicker.open()">access_time</mat-icon>
        <ngx-mat-timepicker #endTimePicker disableClick="false" [timepickerClass]="'custome-time'" [minutesGap]="5"></ngx-mat-timepicker>
        @if(formControls?.endTime?.errors?.invalidTimeRange) {
          <mat-error class="error-text">
            <span i18n><sup>*</sup>End time must be greater than start time</span>
          </mat-error>
        }
        @if(formControls?.startTime?.errors?.invalidTimeValue) {
          <mat-error class="error-text">
            <span i18n><sup>*</sup>End time must be greater than current time</span>
          </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="select-section flex-col space-between gap-25 mt-24" *hbFeatureFlag="false">
      <mat-form-field appearance="fill" class="description-mat-form-field" subscriptSizing="dynamic">
        <mat-label class="description-text" i18n>Description</mat-label>
        <textarea matInput class="desciption-area" rows="4" maxlength="555" formControlName="description"
          i18n-placeholder
          placeholder="Description"
          (paste)="onTextPasted($event)"></textarea>
        <mat-icon class="info-icon cyan-event-icon" i18n-matTooltip matTooltip="You can add here information about price, place, how much players you need and other essential information. You can use emoji to make your message more visible 🙂
          &#13; As an example:
          &#13; 💰50 UAH
          🗺 Location: Ukraine, per item
          🙋‍♂️ Requires 2 players" [matTooltipPosition]="'below'" matTooltipClass="mat-tooltip" #tooltip2="matTooltip"
          (click)="tooltip2.show()">info</mat-icon>
        @if (formControls?.description?.errors?.required) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Required</span>
        </mat-error>
        }
        @if (formControls?.description?.errors?.invalidGoogleMapsLink) {
        <mat-error class="error-text">
          <span i18n><sup>*</sup>Invalid Google Maps Link</span>
        </mat-error>
        }
        @if (formControls?.description?.errors?.maxlength) {
          <mat-error class="error-text">
            <span i18n><sup>*</sup>Maximum length is 555 characters</span>
          </mat-error>
        }
      </mat-form-field>
    </div>

    <div class="date-section flex-row flex-wrap start flex-start gap-10">
      <mat-radio-group formControlName="isIndoor">
        <mat-radio-button color="primary" [value]="true" i18n>Indoor</mat-radio-button>
        <mat-radio-button color="primary" [value]="false" i18n>Outdoor</mat-radio-button>
      </mat-radio-group>
    </div>

    @if ((showPostToTG && !isPrivateEvent) || isMVP) {
      <div class="date-section flex-row">
        <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="isPostToTG" [disabled]="isMVP" [ngModelOptions]="{standalone: true}" i18n>Post to
          Telegram</mat-checkbox>
      </div>
    }

    <div class="date-section flex-row start flex-start gap-25 mdc-form-field">
      <div class="team-size gap-10">
        <p class="team-size-txt" i18n>Team Size</p>
        <mat-form-field appearance="fill" class="custom-mat-form-field">
          <input matInput type="number" class="center-text" formControlName="teamSize"/>

        </mat-form-field>
        <p class="team-size-txt">{{ createEventForm.get('teamSize')!.value ?? '0'}}v{{
          createEventForm.get('teamSize')!.value ?? '0' }}</p>
      </div>

      <div class="team-size gap-10">
        <p class="team-size-txt" i18n>Players needed</p>
        <mat-form-field appearance="fill" class="custom-mat-form-field">
          <input matInput type="number" class="center-text" formControlName="playerLimit"/>
        </mat-form-field>
      </div>
    </div>

    <div class="submut-buttons flex-col space-between align-center gap-10">
      <button class="save-template fill-button" mat-raised-button aria-label="Click here to save template"
        i18n-aria-label (click)="confirmSaveTemplate()" [disabled]="!isTemplateFormChanged" *hbFeatureFlag="true">
        <mat-icon class="va-middle mr-10" svgIcon="bookmark"></mat-icon>
        <span class="va-middle" i18n>Save Template</span>
      </button>
      @if(eventId) {
        <button type="button" mat-button [ngClass]="!isFull ? 'outline-button' : 'fill-button'" (click)="toggleEventStatus(!isFull)" aria-label="Click here to change status of event" i18n-aria-label *hbFeatureFlag="false" ngNoForm>
          @if(!isFull) {
            <span class="va-middle" i18n>Event is full</span>
          } @else {
            <span class="va-middle" i18n>Event is open to join</span>
          }
        </button>
      }
      <button [loading]="loading" class="save-draft fill-button" mat-button (click)="postPublish()"
        aria-label="Click here to post event" i18n-aria-label [disabled]="isBlocked || loading">
        <mat-icon class="va-middle mr-10" svgIcon="send"></mat-icon>
        @if (eventId) {
        <span class="va-middle" i18n>Save changes</span>
        } @else {
        <span class="va-middle" i18n>Post</span>
        }
      </button>
      @if(eventId) {
      <button class="delete-event outline-button" mat-button aria-label="Click here to delete event"
        i18n-aria-label (click)="deleteEvent()">
        <span class="va-middle" i18n>Delete event</span>
      </button>
      }
    </div>
  </div>
</form>
